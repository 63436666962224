import React, { useState, useEffect, Suspense } from "react"
import "./App.css"
import {
    Route,
    Routes,
    Navigate,
    useSearchParams,
    useNavigate
} from "react-router-dom"
import { ToastContainer } from "react-toastify"
import { PublicRoute } from "./routes/PublicRoute"
import "react-toastify/dist/ReactToastify.css"
import { PrivateRouteConfig } from "./routes/privateRoutesConfig"
import {
    PublicRouteConfig,
    PublicGuestRouteConfig
} from "./routes/publiceRoutesConfig"
import PrivateRoutes from "./components/Authentication/PrivateRoutes"
import TimeOut from "./components/SessionTimeOut/TimeOut"
import { CssBaseline, ThemeProvider } from "@mui/material"
import { ColorModeContext, useMode } from "./theme"
import { fetchToken } from "./constant/firebase"
import { saveFirebaseToken } from "./services"
import GuestPublicRoutes from "./components/modules/guestUser/publicGuestUserRoute"
import { Chart } from "chart.js"
import ChartDataLabels from "chartjs-plugin-datalabels"
import { reactRouterUtility } from "./constant/helpersUtility"
import SelfOnboardPage from "./components/modules/SelfOnboard"
import SelfOnboardLayout from "./components/modules/SelfOnboard/Layout"
import InvalidOnboardLinkPage from "./components/modules/SelfOnboard/InvalidOnboardLink"
import OnboardSuccess from "./components/modules/SelfOnboard/OnboardSuccess"
import { PageLoader } from "components/common/Ui/Form"

function App() {
    const [searchParams] = useSearchParams()
    const [user] = useState(() => {
        return JSON.parse(localStorage.getItem("user"))
    })
    const [guestUser] = useState(() => {
        return JSON.parse(localStorage.getItem("guestUser"))
    })
    const [theme, colorMode] = useMode()
    reactRouterUtility.navigate = useNavigate()
    const [firebaseToken, setFirebaseToken] = useState({
        available: false,
        fbToken: ""
    })
    const encryptedTxt = searchParams.get("txt")
    const isSetPassword =
        window.location.pathname.includes("setpassword") && encryptedTxt

    // ** needs to be register outside the hooks, may be we need to register on every re-evaluate
    Chart.register(ChartDataLabels)
    // reactRouterUtility.location = () => {
    //     setLocationKey(currentlocation.key)
    // // }
    // reactRouterUtility.forceUpdate = (val) => setRefresh(() => val)

    useEffect(() => {
        const _handleFireBaseSetup = async () => {
            let fbToken = await fetchToken(setFirebaseToken)
            if (fbToken) {
                let reqBody = {
                    userId: user?.userId,
                    firebaseToken: fbToken
                }
                const response = await saveFirebaseToken(reqBody)
                console.log("fbtoken stored", response)
            }
        }

        // ** null is considered object
        if (!firebaseToken.fbToken && user && Object.keys(user) > 0) {
            _handleFireBaseSetup()
        }
    }, [firebaseToken, user])

    useEffect(() => {
        if (isSetPassword) {
            localStorage.clear("user")
        }
    }, [isSetPassword])

    return (
        <ColorModeContext.Provider value={colorMode}>
            <ThemeProvider theme={theme}>
                <CssBaseline />
                <ToastContainer />
                <Suspense
                    fallback={
                        <PageLoader
                            loaderText="Almost there..."
                            isLoading={true}
                        />
                    }
                >
                    <Routes>
                        {user && (
                            <>
                                <Route element={<PrivateRoutes />}>
                                    {PrivateRouteConfig.map(
                                        ({ name, path, exact, element }) => {
                                            if (
                                                path === "/dashboard" &&
                                                user.userTypeId?.description ===
                                                    "Investor"
                                            ) {
                                                return (
                                                    <Route
                                                        key={name}
                                                        exact={exact}
                                                        path={path}
                                                        element={
                                                            <Navigate
                                                                to="/dealroom"
                                                                replace
                                                            />
                                                        }
                                                    />
                                                )
                                            }
                                            return (
                                                <Route
                                                    key={name}
                                                    exact={exact}
                                                    path={path}
                                                    element={element}
                                                />
                                            )
                                        }
                                    )}
                                </Route>

                                {/** Auto navigate to dealroom for investor users */}
                                {user.userTypeId?.description === "Investor" ? (
                                    <Route
                                        path="/"
                                        element={
                                            <Navigate to="/dealroom" replace />
                                        }
                                    />
                                ) : (
                                    <Route
                                        path="/"
                                        element={
                                            <Navigate to="/dashboard" replace />
                                        }
                                    />
                                )}
                                {user.userTypeId?.description === "Investor" ? (
                                    <Route
                                        path="*"
                                        element={<Navigate to="/dealroom" />}
                                    />
                                ) : (
                                    <Route
                                        path="*"
                                        element={<Navigate to="/dashboard" />}
                                    />
                                )}
                            </>
                        )}

                        {!user && (
                            <>
                                <Route element={<PublicRoute />}>
                                    {PublicRouteConfig.map(
                                        (
                                            { name, path, exact, element },
                                            index
                                        ) => (
                                            <Route
                                                key={index}
                                                exact={exact}
                                                path={path}
                                                element={element}
                                            />
                                        )
                                    )}
                                </Route>

                                <Route
                                    path="*"
                                    element={<Navigate to="/login" />}
                                />
                            </>
                        )}
                        {guestUser && (
                            <>
                                <Route element={<GuestPublicRoutes />}>
                                    {PublicGuestRouteConfig.map(
                                        ({ name, path, exact, element }) => (
                                            <Route
                                                key={name}
                                                exact={exact}
                                                path={path}
                                                element={element}
                                            />
                                        )
                                    )}
                                </Route>
                                {/* <Route path="*" element={<PageNotFound />} /> */}
                                <Route
                                    path="*"
                                    element={<Navigate to="/guestUser" />}
                                />
                            </>
                        )}
                        <Route element={<SelfOnboardLayout />}>
                            <Route
                                element={<SelfOnboardPage />}
                                key="self-register"
                                exact={true}
                                path="/self-register"
                            />
                            <Route
                                element={<InvalidOnboardLinkPage />}
                                key="onboard-token-failed"
                                exact={true}
                                path="/invalid-onboard-link"
                            />
                            <Route
                                element={<OnboardSuccess />}
                                key="onboard-success"
                                exact={true}
                                path="/onboard-success"
                            />
                        </Route>
                    </Routes>
                </Suspense>
                {user && !user?.mobileApp && <TimeOut />}
            </ThemeProvider>
        </ColorModeContext.Provider>
    )
}

export default App
